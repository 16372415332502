$shadow-1 : 0 34px 33px -23px rgba(22, 28, 45, 0.13);
$shadow-2 : 0 31px 34px -20px rgba(0, 0, 0, 0.09);
$shadow-3 : 0 42px 44px -10px rgba(1, 23, 48, 0.12);
$shadow-4 : 0 32px 64px rgba(22, 28, 45, 0.08);
$shadow-5 : 0 62px 64px -10px rgba(1, 23, 48, 0.12);
$shadow-6 : 0 32px 54px rgba(22, 28, 45, 0.16);
$shadow-7 : 0 54px 53px -23px rgba(22, 28, 45, 0.14);;


$gr-shadows: (
  1 : $shadow-1,
  2 : $shadow-2,
  3 : $shadow-3,
  4 : $shadow-4,
  5 : $shadow-5,
  6 : $shadow-6,
  7 : $shadow-7
);